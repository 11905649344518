import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { usePreloadedData } from 'contexts/PreloadedData';
import contentService from 'services/content';
import { getFeatureFlags } from 'utils/featureFlags';
import SiteBannerExperienceFragment from './SiteBannerExperienceFragment';

const cookies = new Cookies();

export const fetchContent = async hasTokenSession =>
  contentService.getContent('Site-Wide-Banner/Site-Banner-Content/master', hasTokenSession);

export const SiteBanner = () => {
  const { siteBanner: showSiteBanner } = getFeatureFlags();
  const { preloadedData } = usePreloadedData();
  const [content, setContent] = useState(preloadedData?.content);

  useEffect(() => {
    if (typeof preloadedData?.content === 'undefined' && showSiteBanner) {
      // fetching cookie value instead of the auth provider to avoid having to make an extra token call when initialising the provider
      const hasTokenSession = cookies.get('X-Has-Token-Session', { doNotParse: true }) === 'true';

      // deepcode ignore PromiseNotCaughtGeneral - contentService has built-in error handling and always resolves
      fetchContent(hasTokenSession).then(result => {
        setContent(result.content);
      });
    }
  }, [preloadedData?.content, showSiteBanner]);

  return (
    <div>
      {!!showSiteBanner && content?.locations?.siteWideBannerContent && (
        <SiteBannerExperienceFragment location={content.locations.siteWideBannerContent} />
      )}
    </div>
  );
};
